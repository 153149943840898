<template>
	<main class="main">
		<section class="container">
			<Crumbs :data="crumbs"></Crumbs>

			<div class="documents__page-wrapper">
				<Documents :data="documents"></Documents>
			</div>
		</section>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'
import Documents from '@/components/Documents.vue'

export default {
	name: 'GuidesAndRegulations',

	components: {
		Crumbs,
		Documents,
	},

	data() {
		return {
			crumbs: [
				{
					link: 'Materials',
					text: 'Материалы',
				},
				{
					link: null,
					text: 'Руководства и регламенты',
				},
			],
		}
	},

	computed: {
		documents() {
			return {
				title: 'Руководства и регламенты',
				type: 'guides',
			}
		},
	},
}
</script>
