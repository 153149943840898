<template>
	<div class="main__box" v-if="data">
		<h1 class="main__title">{{ data.title }}</h1>
		<ul class="documents" v-if="documents && documents.length > 0">
			<li
				class="documents__item"
				v-for="(document, index) in documents"
				:key="index"
			>
				<a :href="document.url" target="__blank" class="documents__link">{{
					document.title
				}}</a>
			</li>
		</ul>
		<p class="main__text" v-else>Документы отсутствуют</p>
	</div>
</template>

<script>
export default {
	name: 'Documents',

	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	computed: {
		getDocuments() {
			const categories = this.$store.getters.CATEGORIES
			let category = null,
				metaCategory = null

			if (categories) {
				for (const key in this.$route.meta) {
					if (categories[key]) {
						category = categories[key]
						metaCategory = key
					}
				}

				this.$store.dispatch('GET_MATERIALS', {
					category: category,
					type: this.data.type,
				})

				return metaCategory
			} else {
				return null
			}
		},

		documents() {
			if (this.getDocuments) {
				return this.$store.getters.MATERIALS[this.getDocuments][this.data.type]
			} else {
				return null
			}
		},
	},
}
</script>
